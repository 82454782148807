<template>
	<v-card
		class="my-10 d-flex flex-column justify-space-around"
		:class="[pricingItem.recommended ? 'mx-6' : 'mx-3']"
		width="200"
		:elevation="pricingItem.recommended ? 12 : 2"
		:style="[pricingItem.recommended ? recommendedClass : '']"
		rounded="xl"
	>
		<v-chip v-if="pricingItem.recommended" color="green" class="my-4 mx-auto">
			{{ $t('subscriptions.recommended') }} <v-icon>mdi-check-circle-outline</v-icon>
		</v-chip>
		<v-card-title :class="{ 'pt-0': pricingItem.recommended }">
			<span class="mx-auto">{{ pricingItem.title }}</span>
		</v-card-title>
		<div class="text-center">
			<span class="text-h4">{{ price }}</span> <span class="text-subtitle-1"> €/{{ periodText }}</span>
		</div>
		<v-divider class="mx-5" />
		<v-card-text>
			<v-list class="py-0" flat dense>
				<v-list-item>
					<v-list-item-content>
						<span class="text-center">{{ pricingItem.item1 }}</span>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						<span class="text-center">{{ pricingItem.item2 }}</span>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-if="pricingItem.item3">
					<v-list-item-content>
						<span class="text-center">{{ pricingItem.item3 }}</span>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-if="pricingItem.item4">
					<v-list-item-content>
						<span class="text-center">{{ pricingItem.item4 }}</span>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card-text>
		<v-card-subtitle class="text-center pt-0 flex-grow-1">
			{{ pricingItem.description }}
		</v-card-subtitle>
		<v-card-actions class="d-flex justify-center align-end" v-if="letSubscribe">
			<v-btn class="mx-auto" color="blue" large rounded @click="subscribe()">
				{{ $t('subscriptions.subscribe') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'SubscriptionPricingItem',
	props: {
		period: {
			type: String,
			required: true
		},
		pricingItem: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	computed: {
		price() {
			if (this.period == 'monthly') return this.pricingItem.priceMonthly
			else if (this.period == 'yearly') return this.pricingItem.priceYearly
			else return '0'
		},
		periodText() {
			if (this.period == 'monthly') return this.$t('subscriptions.month')
			else return this.$t('subscriptions.year')
		},
		letSubscribe() {
			return !!this.authenticated && !!this.company.imgURL && !!this.pricingItem.purchasable
		},
		recommendedClass() {
			return {
				'border-color': 'green',
				'border-width': '4px',
				'border-style': 'solid',
				'border-radius': '8px',
				transform: 'scale(1.15)'
			}
		},
		...mapGetters({
			company: 'companies/company',
			authenticated: 'auth/authenticated'
		})
	},
	methods: {
		subscribe() {
			this.$router.push({ name: 'PricingConfirm', params: { period: this.period, pricingItem: this.pricingItem } })
		}
	}
}
</script>
